// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

@use 'sass:math';
@use 'colours' as *;
@use 'variables' as *;

// Background gradient
// @author Luke Rebeiro
@mixin bg-gradient($a, $b, $direction:0, $o: 1) {
    // directions :
    // 0: left to right
    // 1: top to bottom
    // 2: diagonal

    @if $direction == 0 {
        background: linear-gradient(rgba($a, $o), rgba($b, $o));
    } @else if $direction == 1 {
        background: linear-gradient(to right, rgba($a, $o), rgba($b, $o));
    } @else {
        background: linear-gradient(135deg, rgba($a, $o), rgba($b, $o));
    }
}

/// Event wrapper
@mixin on-event($self: false) {
    @if $self {
        &,
        &:hover,
        &:active,
        &.active,
        &:focus {
            @content;
        }
    } @else {
        &:hover,
        &:active,
        &.active,
        &:focus,
        &:focus-within,
        &:focus-visible {
            @content;
        }
    }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
    #{$context} & {
        @content;
    }
}


@mixin hover-style {
    color: $gray--dark;
    border-color: lighten($gray--dark, 33.3%);
    outline: 0;
}

@mixin focus-style {
    color: $gray--darker;
    border-color: lighten($gray--darker, 33.3%);
    outline: 0;
}

//variable for rem units
$fp-rem-min: $screen-xs;
$fp-rem-max: $screen-xl;

@function strip-unit($number) {
    @return math.div($number, ($number * 0 + 1));
}


// assumes 1rem = 10px
@function convert-rems($value) {
    @if unit($value) == 'rem' {
        @return (strip-unit($value) * 10) * 1px;
    }
    @return $value;
}

@mixin fp($properties, $min-value, $max-value, $min-vw: $fp-rem-min, $max-vw: $fp-rem-max) {
    $min-value: convert-rems($min-value);
    $max-value: convert-rems($max-value);
    $min-vw: convert-rems($min-vw);
    $max-vw: convert-rems($max-vw);

    & {
        @each $property in $properties {
            #{$property}: $min-value;
        }

        @media screen and (min-width: $min-vw) {
            @each $property in $properties {
                //#{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
                #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
            }
        }

        @media screen and (min-width: $max-vw) {
            @each $property in $properties {
                #{$property}: $max-value;
            }
        }
    }
}

@mixin square($w,$h : $w) {
    width: $w;
    height: $h;
}

@mixin fp-padding-side($min-value, $max-value) {
    @include fp(padding-left padding-right, $min-value, $max-value);
}

@mixin fp-padding($min-value, $max-value) {
    @include fp(padding-top padding-bottom, $min-value, $max-value);
}

@mixin template($template) {
    [data-template=#{$template}] & {
        @content;
    }
}

@mixin segment($seg) {
    &[data-personalisation-segment='#{$seg}'] {
        @content;
    }
}

// adds banded highlighting to top level repeaters
@mixin block-banding($block-name) {
    .wp-block[data-type='acf/#{$block-name}'] {
        tr.acf-row:nth-child(even) > td.acf-fields,
        tr.acf-row:nth-child(even) > td.acf-field {
            background-color: $wp-highlight; // this is a default wordpress colour
        }
    }
}

// Block counters
@mixin block_counter($block-name) {
    body.wp-admin {
        counter-reset: $block-name;
    }
}

// calculate card block breakpoint
@function card-breakpoint-calculation($card-min-width,$card-count) {
    @return (($card-min-width * $card-count) + ($card-count + 1) * 40) * 1px;
}


// All text elements
@mixin all-text-elements {
    h1, h2, h3, h4, h5, h6, p {
        @content;
    }
}


// limit the number of lines of text to (almost a character limit)
@mixin line-clamp($lines, $override-global: false) {
    // Line clamp that always applies (ignores theme settings)
    @if $override-global {
        line-clamp: $lines;
        -webkit-line-clamp: $lines;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    // line-clamp class added via init-hooks
    // controlled by theme settings
    @else {
        body.mimas-line-clamp & {
            line-clamp: $lines;
            -webkit-line-clamp: $lines;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}


@mixin flex($direction: unset, $align-content: unset, $align-items: unset, $justify-content: unset) {
    display: flex;
    flex-direction: $direction;
    align-items: $align-items;
    align-content: $align-content;
    justify-content: $justify-content;
}

@mixin position($position: unset, $top: unset, $left: unset, $right: unset, $bottom: unset, $transform: unset) {
    position: $position;
    top: $top;
    left: $left;
    right: $right;
    bottom: $bottom;
    transform: $transform;
}
