@use 'colours' as *;
@use 'variables' as *;
@use 'mixins' as *;

:root {

    //*********************
    // Fonts
    //*********************
    font-size: var(--root-font-size, 10px); // 1rem == 10px

    --base-font: proxima-nova, Helvetica, Arial, sans-serif;
    --heading-font: effra, proxima-nova, Helvetica, Arial, sans-serif;
    --subheading-font: proxima-nova, Helvetica, Arial, sans-serif;
    --thin-font: proxima-nova-thin, proxima-nova, Helvetica, Arial, sans-serif;


    /*********************
    // Core colours
    *********************/
    --color-lime: #{$color-lime};
    --color-magenta: #{$color-magenta};
    --color-deep-blue: #{$color-deep-blue};

    /*********************
    // Secondary
    *********************/
    --white: #{$white};
    --black: #{$black};
    --body-black: #{$body-black};
    --black--bg: #{$black--bg};
    --text-color: #{$text-color};

    --gray: #{$gray};
    --gray--light: #{$gray--light};
    --gray--lighter: #{$gray--lighter};
    --gray--lightest: #{$gray--lightest};
    --gray--dark: #{$gray--dark};
    --gray--darker: #{$gray--darker};
    --gray--darkest: #{$gray--darkest};



    /*********************
    // Tertiary
    *********************/
    --color-apple: #{$color-apple};
    --color-apple--light: #{$color-apple--light};
    --color-banana: #{$color-banana};
    --color-banana--light: #{$color-banana--light};
    --color-chilli: #{$color-chilli};
    --color-deep-blue--light: #{$color-deep-blue--light};
    --color-lime--light: #{$color-lime--light};
    --color-magenta--light: #{$color-magenta--light};
    --color-magenta--dark: #{$color-magenta--dark};
    --color-strawberry: #{$color-strawberry};
    --color-strawberry--light: #{$color-strawberry--light};
    --color-strawberry--dark: #{$color-strawberry--dark};

    --error: #ffecec;
    --success: #e9ffd9;

    --primary-color: #051c2a;


    /*********************
    // Gaps and sizes
    *********************/

    --gap: #{$gap};
    --gap-element: #{$gap-element};
    --gap-small: #{$gap-small};
    --gap-smaller: #{$gap-smaller};
    --gap-smallest: #{$gap-smallest};
    --gap-large: #{$gap-large};
    --gap-larger: #{$gap-larger};
    --gap-largest: #{$gap-largest};
    --gap-xlarge: #{$gap-xlarge};

    @include fp(--gap-half, 1rem, 2rem);
    @include fp(--gap, 2rem, 4rem);
    @include fp(--gap-double, 4rem, 8rem);
    @include fp(--gap-negative, -2rem, -4rem);


    --grid-sidebar-w: 28rem;

}
