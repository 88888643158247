/*********************
// core colours
*********************/

$color-lime: #c3d82e;
$color-magenta: #e52592;
$color-deep-blue: #5525e5;

/*********************
// secondary
*********************/
$white: #fff;
$black: #000;
$body-black: #051c2a;
$black--90: #1a1a1a;
$black--bg: $black--90;
$text-color: #4b4b4b;
$link-color: $black;

$gray: #b3b3b3;
$gray--light: #e8e8e8;
$gray--lighter: #f0f0f0;
$gray--lightest: #f7f7f7;
$gray--dark: #717171;
$gray--darker: #4b4b4b;
$gray--darkest: #303030;

$gray--bg: $gray--lightest;
$gray--border: $gray--light;
$gray--footer: $gray;
$gray--link: $gray--dark;
$gray--table: $gray--light;

/*********************
// Tertiary
*********************/

$color-apple: #1de57e;
$color-apple--light: #e6f9ef;
$color-apple--dark: #61eda5;
$color-banana: #fff3a8;
$color-banana--light: #fffadc;
$color-banana--dark: #8d8761;
$color-chilli: #f02020;
$color-deep-blue--light: #f1eefd;
$color-deep-blue--dark: #a992f1;
$color-purple--dark: #5a126e;  //visited link for deep-blue
$color-lime--light: #141605;
$color-lime--lighter: #f3ff99; //visited link for lime
$color-magenta--dark: #ca2381;
$color-magenta--light: #fdeef7;
$color-strawberry: #ff615e;
$color-strawberry--light: #ffefef;
$color-strawberry--dark: #1f0c0b;

/*********************
// notifications
*********************/
$color-notice: #57d897;
$color-notice--success: $color-notice;
$color-notice--success-l: $color-apple--light;
$color-notice-bg--success: #dceb75;
$color-notice-bg--error: $color-strawberry;
$color-notice-border: #ffbc26;
$color-notice-border--success: #9cb208;


/*********************
// Legacy
*********************/
$color-magenta-light: $color-magenta--light;
$color-magenta-dark: $color-magenta--dark;
$color-lilac: #eeeafd;
$color-lavender: #f6f4fe;
$yellow: #ffc000;
$color-danger: #c3423f;

// Gradients
$gradient--yellow: linear-gradient(rgba($yellow, 0.8), rgba(#d6cfa6, 0.8));
$gradient--yellow--h: linear-gradient(to right, $yellow, #fffdcc);
$gradient--green: linear-gradient(to right, rgba(#47AB43, .8), rgba(#294C43, .8));
$gradient--purple: linear-gradient(to right, $color-deep-blue, $color-magenta);


// OTHER
$wp-highlight: #fdf8ee; // this is a default wordpress colour

// for loops
$colors: (
        'apple': $color-apple,
        'banana': $color-banana,
        'chilli' : $color-chilli,
        'dark-magenta': $color-magenta--dark,
        'deep-blue': $color-deep-blue,
        'gray-dark': $gray--dark,
        'gray' : $gray,
        'lime': $color-lime,
        'magenta': $color-magenta,
        'portal-blue' : #22547c,
        'strawberry': $color-strawberry,
        'white': $white,
        'yellow' : $yellow,
);
