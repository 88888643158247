// -----------------------------------------------------------------------------
// This file contains all application-wide Sass placeholders.
// -----------------------------------------------------------------------------
@use 'colours' as *;
@use 'variables' as *;
@use 'mixins' as *;
@use 'mq' as *;
@use 'mq-config' as *;

%flex {
    display: flex;
}

%flex-align-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

%flex-justify-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

%flex-col {
    display: flex;
    flex-direction: column;
}

%flex-col-center {
    @extend %flex-align-center;
    flex-direction: column;
}

%max-height-transition {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height $transition, opacity $transition;

    &.active {
        max-height: 100vh;
        opacity: 1;
    }
}

%abs {
    position: absolute;
}

%abs--cover {
    @extend %abs;
    inset: 0;
}

%blanket {
    @extend %abs--cover;
    position: fixed;
    z-index: 70;
}

%abs--center {
    @extend %abs;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

%a-border {
    content: '';
    position: absolute;
    height: 2px;
    left: 0;
    bottom: 1px;
    transition: $transition;
    background-color: var(--link-color-a);
    width: 100%;
}

%invisible {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

%visible {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
}

%centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

%border {
    border: 1px solid $gray--lighter;
    border-radius: 3px;
}

// border radius
%border--round {
    @extend %border;
    border-radius: 10px;
}

%block-banding {
    tr.acf-row:nth-child(even) > td.acf-field,
    tr.acf-row:nth-child(even) > td.acf-fields,
    .acf-flexible-content .values .layout:nth-child(even) {
        background-color: var(--banding-bg, #{$wp-highlight});
    }
}

%br-left {
    border-radius: 5px 0 0 5px;
}

%br-right {
    border-radius: 0 5px 5px 0;
}

%br-top {
    border-radius: 5px 5px 0 0;
}

%br-bottom {
    border-radius: 0 0 5px 5px;
}

%outline {
    outline: $outline;
    border-radius: 5px;
    outline-offset: 0px;
    z-index: 10;
}

%module-bg {
    @extend %pseudo--cover;
    background-color: var(--module-bg);
    z-index: -1;

    @media (min-width: $screen-lg) {
        left: -5vw;
        right: -5vw;
    }
}


/*
This placeholder is usually added to blocks using the data-type attribute selector
    [data-type="acf/block-name"] {}
*/

%no-editor-preview {
    --block-name: none;
    --editor-no-preview: '*Full preview unavailable for ' var(--block-name, 'this block.');
    --bg-color: var(--color-banana);
    z-index: 10;

    > .acf-block-body,
    &.is-selected::after,
    &.is-selected::before,
    &:focus::before,
    &:focus::after {
        display: none;
    }

    &.is-selected > .acf-block-body {
        display: block;
    }

    // only for admin screens
    .wp-admin & {
        position: relative;
        min-height: 80px;

        &::before,
        &::after {
            @extend %flex-col-center;
            position: absolute;
            left: 0;
            right: auto;
            padding: 0 var(--gap-half);
            background-color: var(--bg-color);
            max-width: $content-width-max;
            height: 40px;
            margin: 0 auto;
            cursor: pointer;
            z-index: 11;
        }

        &::before {
            @extend %flex-col-center;
            content: var(--block-name, 'Block');
            top: 0;
            font-weight: bold;
            font-variant: all-small-caps;
        }

        &::after {
            content: var(--editor-no-preview);
            top: 40px;
            padding: $gap var(--gap-half);
        }

    }

}

%notification {
    @extend %small;
    @extend %flex;
    background-color: var(--notice-bg, #{$color-banana});
    align-items: center;
    position: relative;
    padding: $gap-small $gap-large $gap-small $gap;
    margin: 0 $gap var(--gap);
    width: fit-content; // check notification on standard page with side nav if changing this
    height: fit-content;
    min-height: $gap-xlarge;
    border-radius: 4px;
    overflow: hidden;

    @include mq(sm) {
        margin: 0 $gap-larger var(--gap) ;
    }

    @include mq(nb) {
        margin: 0 0 var(--gap);
    }

    &,
    p {
        color: var(--notice-color, #{$black});
    }

    &[data-variant='error'],
    &[data-variant='important'] {
        --notice-bg: #{$color-strawberry};
    }

    &[data-variant='success'] {
        --notice-bg: #{$color-notice};
    }

    .ico {
        --ico-color-0: var(--notice-color);
        align-self: flex-start;
        margin: 0 $gap 0 0;
    }

    p {
        font-size: $font-size-small;

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        @extend %link;
        font-size: 1em;
    }
}

%pseudo {
    @extend %abs;
    content: '';
}

%pseudo--cover {
    @extend %abs--cover;
    content: '';
    transition: opacity $transition;
}


%pseudo--line {
    @extend %pseudo;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: var(--line-color, #{$color-deep-blue});
}


// for use with <a>
%link {
    text-decoration: underline;
    display: inline;

    @include on-event() {
        --link-color: var(--link-color-a);
    }

    &:active {
        text-decoration: none;
    }
}

%custom-scrollbar {

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: $gray;
        border-radius: 10px;
    }
}

%custom-scrollbar-narrow {

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: $gray;
        border-radius: 3px;
    }
}

%smallest {
    @include fp(font-size, 1rem, $font-size-smallest);
}

%smaller {
    @include fp(font-size, $font-size-smallest, $font-size-smaller);
}

%small {
    @include fp(font-size, $font-size-smaller, $font-size-small);
    line-height: $line-height-small;
}

%regular {
    @include fp(font-size, $font-size-small, $font-size);
}

%large {
    @include fp(font-size, $font-size, $font-size-large);
    font-weight: $font-weight-semibold;
}

// custom tables from plugins and for hardcoded tables - i.e. student atar profiles in Study
%table_override { // used by wpDataTables plugin
    display: block;
    overflow-x: auto;
    overflow-y: hidden;

    figure {
        margin: 0;
    }

    table {
        max-width: 100%;

        thead tr th,
        tbody tr td {
            font-family: $base-font !important;
        }

        tbody tr {

            &:hover > td {
                background-color: transparent !important;
            }

            td {
                color: $gray--darker !important;
                min-width: 200px;
            }
        }
    }
}


%contained {
    width: 100%;
    max-width: calc(#{$contained-max-width} + (var(--gap) * 2));
    margin-left: auto;
    margin-right: auto;
    padding: 0 var(--gap);

    &-small {
        width: 100%;
        max-width: $contained-max-width;
        margin-left: auto;
        margin-right: auto;
        padding: 0 var(--gap-largest);

    }
}

// This creates an icon using the ::before and ::after tags of an element. See block style heading for reference.
%pseudo--icon {
    --default-pseudo-icon-url: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M15.71 2.29l-1.42 1.42L25.59 15H3v2h22.59l-11.3 11.29 1.42 1.42L29.41 16 15.71 2.29z"/></svg>');
    --pseudo-icon-bg-color: var(--white);
    --pseudo-icon-bg-opacity: 1;

    &::before,
    &::after {
        @extend %pseudo;
        right: 0;
        min-width: $gap-larger;
        min-height: $gap-larger;
        transition: background-color $transition-slow;
    }

    &::before {
        background-color: var(--pseudo-icon-bg-color);
        opacity: var(--pseudo-icon-bg-opacity);
    }

    &::after {
        background-color: var(--ico-fill);
        mask: var(--pseudo-icon-url, var(--default-pseudo-icon-url)) no-repeat center;
        mask-size: $gap;
    }
}

// icon on the right of an element
// see accordion.scss
%pseudo--ico {
    @extend %pseudo;
    @include square(24px);
    background-image: var(--link-ico, url(#{ $svg-dir + 'ico-arrow--blue.svg'}));
    inset: auto 2.4rem auto auto;
}

%pseudo--ico-left {
    @extend %pseudo--ico;
    inset: auto auto auto 2.4rem;
}


// Gutenberg editor defines column names for full or default widths. See editor-style.scss
%editor-block-full-w {
    --editor-block-cols: f-start / f-end;
}

%mimas-block-grid {
    display: grid;
    grid-template-columns: [start] repeat(var(--col-repeat, 4), minmax(var(--col-width, 78px), 1fr)) [end];
    gap: 0 var(--g-gap-h, 20px);

    @include mq(sm) {
        --col-repeat: 6;
        --col-width: 98px;
    }

    @media (min-width: 1280px) {
        --col-repeat: 12;
        --col-width: 60px;
        --g-gap-h: 20px;
    }

    @media (min-width: 1528px) {
        --col-width: 72px;
        --g-gap-h: 24px;
    }
}

// Show a 'Deprecated' notice in the block selector; or similar location
%block-deprecated-notice {
    cursor: not-allowed;
    position: relative;

    &::before {
        @extend %pseudo--cover;
        border: 1px dashed $color-chilli;
    }

    &::after {
        content: var(--block-depn, 'Deprecated');
        background-color: white;
        color: $color-notice-bg--error;
        font-size: 0.9em;
        font-weight: $font-weight-semibold;
        font-variant: all-petite-caps;
        line-height: 1;
        padding-bottom: 2px;
    }

    &:hover {
        &::before {
            background-color: $white;
            content: var(--block-depn-h, '');
        }

        .block-editor-block-types-list__item-title {
            visibility: hidden;
        }
    }

}
