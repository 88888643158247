// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
@use 'colours' as *;

//*********************
// URI paths
//*********************
$base-url: '/src/';
$mimas-dir: '../../../mimas/';
$svg-dir: '../../../mimas/src/img/icons/';
$icon-dir: $svg-dir;
$font-dir: '../../dist/fonts';
$img-dir:  '../../src/img/';// if changing check login page images, and all other references too
$img-placeholder: '';
//*********************
// Text & Fonts
//*********************
$base-font: var(--base-font);
$heading-font: var(--heading-font);
$subheading-font: var(--base-font);
$thin-font: var(--thin-font);

// font sizes
$font-size-smallest: 1.2rem;
$font-size-smaller: 1.4rem;
$font-size-small: 1.6rem;
$font-size: 1.8rem !default;
$font-size-large: 2rem;
$font-size-larger: 2.2rem;
$font-size-largest: 2.4rem;

// font weights
$bold: 700;
$font-weight-light: 300;
$font-weight-base: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: $bold;

// line heights, unitless
$line-height: 1.5;
$line-height-small: 1.4;
$line-height-smaller: 1.2;


/*********************
// Transitions
*********************/

$transition-time: 0.15s;
$transition-ease: ease;

$transition: 0.15s $transition-ease;
$transition-slow: 0.3s $transition-ease;
$transition-slower: 0.5s $transition-ease;
$transition-slowest: 0.8s $transition-ease;
$transition-fast: 0.15s; //obsolete - use $transition
$transition-fastest: $transition-fast; // obsoleted


/*********************
// Breakpoints
*********************/
$screen-xs: 500px;
$screen-sm: 768px;
$screen-md: 900px;
$screen-md-max: 1024px; // legacy
$screen-lg: 1280px;
$screen-xl: 1440px;
$screen-xxl: 1600px; //affects margin on standard page content
$screen-hd: 1920px;
$screen-hd--m: 2560px;
$screen-hd--l: 3840px;
$screen-4k: 4096px;

$nav-break: 1280px;
$grid-c-max: 1528px;
$study-search-page-break-point: 1024px;

//*********************
// specified widths and heights
//*********************
$content-width-min: 28rem !default;
$content-width-max: 90rem !default;
$block-inner-width-max: 110rem !default;
$block-max-width: 124rem !default;
$module-max-width: 160rem !default;
$contained-max-width: 184rem !default;
$header-device-height: 8rem !default;

$sidebar-width: var(--grid-sidebar-w);

$card-min-width: 280px;
$card-max-width: 480px;
$stamp-max-width: 350px;


/*********************
// Gaps and sizes
*********************/
$gap-none: 0rem;
$gap-xsmaller: 0.4rem;
$gap-xsmall: 0.8rem;
$gap-smallest: 1.2rem;
$gap-smaller: 1.6rem;
$gap-small: 2rem;
$gap: 2.4rem;
$gap-large: 3.2rem;
$gap-larger: 4rem;
$gap-largest: 4.8rem;
$gap-xlarge: 5.6rem;
$gap-xlarger: 6.4rem;
$gap-xlargest: 7.2rem;
$gap-xxlarge: 8rem;
$gap-xxlarger: 10rem;

$gap-element: $gap-large;

// Borders
$border-radius-small: 0.3rem;
$border-radius: 0.8rem;
$border-radius-large: 1rem;
$border-radius-round: 50%;

$border-width: 0.1rem;
$border-width-large: 0.2rem;
$border-width-larger: 0.3rem;

$border-gray-dark: 1px solid $gray--dark;
$border--gray: 1px solid $gray;
$border-gray-light: 1px solid $gray--light;
$border-gray-lighter: 1px solid $gray--lighter;
$border-gray-lightest: 1px solid $gray--lightest;
$border-gray-lighter--thick: 2px solid $gray--lighter;

$border-purple: 2px outset $color-deep-blue;
$border-deep-blue: 1px solid $color-deep-blue;

$outline: 2px solid var(--link-color-outline, #{$color-magenta});

/*********************
// Opacity and shadows
*********************/
$opacity: 0.8;
$opacity-half: 0.5;
$opacity--light: 0.6;
$opacity--lighter: 0.4;
$opacity--lightest: 0.1;

// overlay colours
$overlay--light: rgba($black, 0.15);
$overlay--lightest: rgba($black, 0.05);
$overlay: rgba($black, $opacity--lighter);
$overlay--dark: rgba($black, $opacity-half);
$overlay--darker: rgba($black, $opacity);

$overlay--white: rgba($white, $opacity);
$overlay--gray: rgba($gray, $opacity);
$overlay--yellow: rgba(#FFC000, $opacity);
$overlay--deep-blue: rgba($color-deep-blue, $opacity--light);

$modal-overlay: rgba($gray, $opacity--lighter);
$modal-overlay--dark: rgba($gray, $opacity);

// Shadow
$box-shadow: 0 0 var(--gap-half, 1rem) $overlay--light;
$box-shadow--larger: 0 0 $gap $overlay--light;


// Block vars - when designs gets carried away...
$support-break: 520px;
$content-container-block-offset: 12.5%;
$content-container-block-offset-negative: -12.5%;
$icon--large: 3rem;
