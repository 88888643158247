/*********************
// core colours
*********************/
/*********************
// secondary
*********************/
/*********************
// Tertiary
*********************/
/*********************
// notifications
*********************/
/*********************
// Legacy
*********************/
/*********************
// Transitions
*********************/
/*********************
// Breakpoints
*********************/
/*********************
// Gaps and sizes
*********************/
/*********************
// Opacity and shadows
*********************/
/*
This placeholder is usually added to blocks using the data-type attribute selector
    [data-type="acf/block-name"] {}
*/
.footer,
.footer p {
  line-height: 1.4;
}
.footer,
.footer p {
  font-size: 14px;
}
@media screen and (min-width: 500px) {
  .footer,
  .footer p {
    font-size: calc(14px + 2 * (100vw - 500px) / 940);
  }
}
@media screen and (min-width: 1440px) {
  .footer,
  .footer p {
    font-size: 16px;
  }
}

:root {
  font-size: var(--root-font-size, 10px);
  --base-font: proxima-nova, Helvetica, Arial, sans-serif;
  --heading-font: effra, proxima-nova, Helvetica, Arial, sans-serif;
  --subheading-font: proxima-nova, Helvetica, Arial, sans-serif;
  --thin-font: proxima-nova-thin, proxima-nova, Helvetica, Arial, sans-serif;
  /*********************
  // Core colours
  *********************/
  --color-lime: #c3d82e;
  --color-magenta: #e52592;
  --color-deep-blue: #5525e5;
  /*********************
  // Secondary
  *********************/
  --white: #fff;
  --black: #000;
  --body-black: #051c2a;
  --black--bg: #1a1a1a;
  --text-color: #4b4b4b;
  --gray: #b3b3b3;
  --gray--light: #e8e8e8;
  --gray--lighter: #f0f0f0;
  --gray--lightest: #f7f7f7;
  --gray--dark: #717171;
  --gray--darker: #4b4b4b;
  --gray--darkest: #303030;
  /*********************
  // Tertiary
  *********************/
  --color-apple: #1de57e;
  --color-apple--light: #e6f9ef;
  --color-banana: #fff3a8;
  --color-banana--light: #fffadc;
  --color-chilli: #f02020;
  --color-deep-blue--light: #f1eefd;
  --color-lime--light: #141605;
  --color-magenta--light: #fdeef7;
  --color-magenta--dark: #ca2381;
  --color-strawberry: #ff615e;
  --color-strawberry--light: #ffefef;
  --color-strawberry--dark: #1f0c0b;
  --error: #ffecec;
  --success: #e9ffd9;
  --primary-color: #051c2a;
  /*********************
  // Gaps and sizes
  *********************/
  --gap: 2.4rem;
  --gap-element: 3.2rem;
  --gap-small: 2rem;
  --gap-smaller: 1.6rem;
  --gap-smallest: 1.2rem;
  --gap-large: 3.2rem;
  --gap-larger: 4rem;
  --gap-largest: 4.8rem;
  --gap-xlarge: 5.6rem;
  --grid-sidebar-w: 28rem;
}
:root {
  --gap-half: 10px;
}
@media screen and (min-width: 500px) {
  :root {
    --gap-half: calc(10px + 10 * (100vw - 500px) / 940);
  }
}
@media screen and (min-width: 1440px) {
  :root {
    --gap-half: 20px;
  }
}
:root {
  --gap: 20px;
}
@media screen and (min-width: 500px) {
  :root {
    --gap: calc(20px + 20 * (100vw - 500px) / 940);
  }
}
@media screen and (min-width: 1440px) {
  :root {
    --gap: 40px;
  }
}
:root {
  --gap-double: 40px;
}
@media screen and (min-width: 500px) {
  :root {
    --gap-double: calc(40px + 40 * (100vw - 500px) / 940);
  }
}
@media screen and (min-width: 1440px) {
  :root {
    --gap-double: 80px;
  }
}
:root {
  --gap-negative: -20px;
}
@media screen and (min-width: 500px) {
  :root {
    --gap-negative: calc(-20px + -20 * (100vw - 500px) / 940);
  }
}
@media screen and (min-width: 1440px) {
  :root {
    --gap-negative: -40px;
  }
}

.footer {
  content-visibility: auto;
  background-color: #000;
  color: #f7f7f7;
  position: relative;
  z-index: 0;
  display: block;
  overflow: hidden;
  padding: 0 var(--gap);
  z-index: 10;
}
@media (min-width: 80em) {
  .footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "campuses teachingareas" "socials  sponsors" "links  message";
  }
}
.footer > div:nth-last-child(-n+2) {
  padding: var(--gap) 0;
}
.footer > div {
  padding: var(--gap) 0 0;
  display: flex;
}
@media (min-width: 31.25em) {
  .footer > div {
    padding: var(--gap) 0;
  }
}
@media (max-width: 79.99em) {
  .footer > div.footer__socials, .footer > div.footer__sponsers {
    padding: 2.4rem 0 3.2rem;
  }
}
@media (max-width: 31.24em) {
  .footer > div.footer__socials, .footer > div.footer__sponsers {
    padding: 1.2rem 0 3.2rem;
  }
}
@media (min-width: 80em) {
  .footer {
    padding-top: 3.2rem;
  }
}
.footer .footer__legal-links,
.footer .footer__message {
  padding: 2.4rem 0 0;
}
@media (min-width: 80em) {
  .footer .footer__legal-links,
  .footer .footer__message {
    padding: 4rem 0 4.8rem;
  }
}
@media (max-width: 31.24em) {
  .footer .footer__campuses,
  .footer .footer__teaching-areas {
    padding: 3.2rem 0 0;
  }
}
.footer__campuses a:hover, .footer__teaching-areas a:hover, .footer__legal-links a:hover {
  --link-color-a: var(--color-lime);
}
.footer,
.footer p {
  color: #b3b3b3;
}
.footer:last-child,
.footer p:last-child {
  margin-bottom: 0;
}
.footer h2 {
  color: currentColor;
}
.footer a {
  text-decoration: underline;
  line-height: 1.5;
  --link-color: var(--white);
  --link-color-a: var(--color-lime);
  word-break: keep-all;
}
.footer a:focus {
  text-decoration: none;
}
.footer ul {
  columns: 2;
  list-style: none;
}
.footer li {
  font-size: inherit;
}
.footer > div {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 79.99em) {
  .footer__campuses {
    float: left;
  }
}
.footer__campuses, .footer__teaching-areas {
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
}
@media (min-width: 31.25em) {
  .footer__campuses, .footer__teaching-areas {
    flex-direction: row;
    margin-bottom: 2.4rem;
    padding: 0;
    width: 100%;
  }
}
.footer__campuses h2, .footer__teaching-areas h2 {
  margin-bottom: 3.2rem;
  color: #fff;
  margin-top: 0;
  font-size: 1.8rem;
  font-weight: 600;
  font-family: var(--base-font);
}
@media (min-width: 31.25em) {
  .footer__campuses h2, .footer__teaching-areas h2 {
    flex: 1;
    color: #fff;
    margin-bottom: 0;
    font-size: 1.8rem;
  }
}
.footer__campuses h2::before, .footer__teaching-areas h2::before {
  height: 4px;
}
@media (min-width: 80em) {
  .footer__campuses h2::before, .footer__teaching-areas h2::before {
    height: 6px;
  }
}
.footer__campuses ul, .footer__teaching-areas ul {
  padding: 0;
  margin: 0;
  width: 100%;
  columns: 1;
}
@media (min-width: 31.25em) {
  .footer__campuses ul, .footer__teaching-areas ul {
    flex: 2;
    columns: 2;
  }
}
.footer__campuses li, .footer__teaching-areas li {
  margin-bottom: 1.2rem;
}
@media (min-width: 31.25em) {
  .footer__campuses li, .footer__teaching-areas li {
    margin-bottom: 2.4rem;
    display: inline-block;
    width: 100%;
  }
}
.footer__campuses a, .footer__teaching-areas a {
  vertical-align: top;
}
.footer__campuses {
  grid-area: campuses;
}
.footer__teaching-areas {
  grid-area: teachingareas;
}
.footer__message {
  grid-area: message;
  margin: 2.4rem 0;
  width: 100%;
}
@media (min-width: 80em) {
  .footer__message {
    margin: 0;
    width: auto;
  }
}
.footer__message p {
  line-height: 1.5;
}
@media (max-width: 31.24em) {
  .footer__message p {
    font-size: 1.4rem;
  }
}
.footer__campuses, .footer__message {
  padding: var(--gap-largest) 0;
}
.footer__socials, .footer__sponsors {
  padding: var(--gap) 0;
}
.footer__socials svg, .footer__sponsors svg {
  opacity: 0.8;
}
.footer__socials svg:hover, .footer__sponsors svg:hover {
  opacity: 1;
}
@media (min-width: 80em) {
  .footer__socials, .footer__sponsors {
    display: flex;
    align-items: center;
    border: 2px solid var(--gray--darker);
    border-width: 2px 0;
    position: relative;
  }
}
.footer__socials {
  grid-area: socials;
  justify-content: space-around;
  border: 2px solid var(--gray--darker);
  border-width: 0 0 2px 0;
}
@media (min-width: 80em) {
  .footer__socials {
    justify-content: start;
    border-width: 2px 0;
  }
}
.footer__socials a {
  margin-right: 0;
  display: flex;
}
@media (min-width: 31.25em) {
  .footer__socials a {
    margin-right: var(--gap);
  }
}
.footer__socials .ico {
  text-align: center;
  margin: var(--gap-half) 0;
}
@media (min-width: 31.25em) {
  .footer__socials .ico {
    text-align: left;
    margin: 0;
  }
}
.footer__socials .ico svg {
  fill: #fff;
  width: 24px;
}
@media (min-width: 80em) {
  .footer__socials .ico svg {
    width: var(--ico-width);
  }
}
.footer__sponsors {
  grid-area: sponsors;
  overflow-x: scroll;
  padding: 0;
  margin-bottom: var(--gap);
}
@media (min-width: 31.25em) {
  .footer__sponsors {
    justify-content: space-between;
    flex-wrap: wrap;
    overflow-x: hidden;
  }
}
@media (min-width: 80em) {
  .footer__sponsors {
    margin-bottom: 0;
  }
}
.footer__sponsors a {
  display: flex;
  margin: var(--gap-half);
  height: 50px;
}
.footer__sponsors a:hover {
  --link-color-a: white;
}
@media (min-width: 31.25em) {
  .footer__sponsors a {
    margin: 0;
  }
}
.footer__legal-links {
  grid-area: links;
  flex-direction: column;
}
@media (max-width: 79.99em) {
  .footer__legal-links {
    margin-bottom: 4.8rem;
  }
}
.footer__legal-links li {
  text-align: left;
  margin-bottom: 1.2rem;
}
@media (min-width: 31.25em) {
  .footer__legal-links li {
    text-align: center;
    margin-bottom: 0;
    margin-right: 3.2rem;
  }
}
@media (min-width: 80em) {
  .footer__legal-links li {
    text-align: left;
  }
}
.footer__legal-links ul {
  padding: 0;
  margin: 0;
  columns: 2;
}
@media (min-width: 31.25em) {
  .footer__legal-links ul {
    columns: 5;
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 80em) {
  .footer__legal-links ul {
    justify-content: flex-start;
  }
}
.footer__codes {
  display: flex;
  flex-direction: column;
  margin-top: 3.2rem;
}
@media (min-width: 31.25em) {
  .footer__codes {
    flex-direction: row;
    margin-top: 3.2rem;
  }
}
.footer__codes p {
  margin-right: 2.4rem;
  margin-bottom: 1.2rem;
  font-size: 1.2rem;
  line-height: 1.5;
}
@media (min-width: 31.25em) {
  .footer__codes p {
    margin-bottom: 2.4rem;
    font-size: 1.6rem;
  }
}
.footer .h-underline {
  --u-gap: 1rem;
}
@media (min-width: 80em) {
  .footer .h-underline {
    --u-gap: 2.5rem;
  }
}

#socials-wechatqr {
  display: none;
  padding: 3.2rem;
}
#socials-wechatqr img {
  margin-left: auto;
  margin-right: auto;
}