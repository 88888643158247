// -----------------------------------------------------------------------------
// Contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
@use "../abstracts" as *;

.footer {
    content-visibility: auto;
    background-color: $black;
    color: $gray--lightest;
    position: relative;
    z-index: 0;
    display: block;
    overflow: hidden;
    padding: 0 var(--gap);
    z-index: 10;

    // @formatter:on
    @include mq(nb) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "campuses teachingareas" "socials  sponsors" "links  message";
    }

    > div:nth-last-child(-n+2) {
        padding: var(--gap) 0;
    }

    > div {
        padding: var(--gap) 0 0;
        display: flex;

        @include mq(xs) {
            padding: var(--gap) 0;
        }

        &.footer__socials, &.footer__sponsers {

            @include mq($until: nb) {
                padding: $gap 0 $gap-large;
            }

            @include mq($until: xs) {
                padding: $gap-smallest 0 $gap-element;
            }

        }
    }

    @include mq(lg) {
        padding-top: $gap-large;
    }

    .footer__legal-links,
    .footer__message {
        padding: $gap 0 0;

        @include mq(nb) {
            padding: $gap-larger 0 $gap-largest;
        }
    }

    .footer__campuses,
    .footer__teaching-areas {
        @include mq($until: xs) {
            padding: $gap-large 0 0;
        }
    }

    &__campuses,
    &__teaching-areas,
    &__legal-links {

        a:hover {

            --link-color-a: var(--color-lime);
        }
    }

    &,
    p {
        @extend %small;
        color: $gray--footer;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h2 {
        color: currentColor;
    }

    a {
        text-decoration: underline;
        line-height: $line-height;
        --link-color: var(--white);
        --link-color-a: var(--color-lime);
        word-break: keep-all;

        &:focus {
            text-decoration: none;
        }
    }

    ul {
        columns: 2;
        list-style: none;
    }

    li {
        font-size: inherit;
    }

    > div {
        display: flex;
        flex-wrap: wrap;

    }

    &__campuses {
        @include mq($until: nb) {
            float: left;
        }
    }

    &__campuses,
    &__teaching-areas {
        align-items: flex-start;
        flex-direction: column;
        width: 50%;

        @include mq(xs) {
            flex-direction: row;
            margin-bottom: $gap;
            padding: 0;
            width: 100%;
        }

        h2 {
            margin-bottom: $gap-element;
            color: $white;
            margin-top: 0;
            font-size: 1.8rem;
            font-weight: $font-weight-semibold;
            font-family: $base-font;

            @include mq(xs) {
                flex: 1;
                color: $white;
                margin-bottom: 0;
                font-size: $font-size;
            }

            &::before {
                height: 4px;

                @include mq(nb) {
                    height: 6px;
                }
            }
        }

        ul {
            padding: 0;
            margin: 0;
            width: 100%;
            columns: 1;

            @include mq(xs) {
                flex: 2;
                columns: 2;
            }
        }

        li {
            margin-bottom: $gap-smallest;

            @include mq(xs) {
                margin-bottom: $gap;
                display: inline-block;
                width: 100%;
            }
        }

        a {
            vertical-align: top;

        }
    }

    &__campuses {
        grid-area: campuses;

    }

    &__teaching-areas {
        grid-area: teachingareas;
    }

    &__message {
        grid-area: message;
        margin: $gap 0;
        width: 100%;

        @include mq(nb) {
            margin: 0;
            width: auto;
        }

        p {
            line-height: $line-height;

            @include mq($until: xs) {
                font-size: $font-size-smaller;
            }
        }
    }

    &__campuses,
    &__message {
        padding: var(--gap-largest) 0;
    }

    &__socials,
    &__sponsors {
        padding: var(--gap) 0;

        svg {
            opacity: $opacity;

            &:hover {
                opacity: 1;
            }
        }

        @include mq(nb) {
            display: flex;
            align-items: center;
            border: 2px solid var(--gray--darker);
            border-width: 2px 0;
            position: relative;
        }
    }

    &__socials {
        grid-area: socials;
        justify-content: space-around;
        border: 2px solid var(--gray--darker);
        border-width: 0 0 2px 0;

        @include mq(nb) {
            justify-content: start;
            border-width: 2px 0;
        }

        a {
            margin-right: 0;
            display: flex;

            @include mq(xs) {
                margin-right: var(--gap);
            }
        }

        .ico {
            text-align: center;
            margin: var(--gap-half) 0;

            @include mq(xs) {
                text-align: left;
                margin: 0;
            }
        }

        .ico svg {
            fill: $white;
            width: 24px;

            @include mq(nb) {
                width: var(--ico-width);
            }
        }
    }

    &__sponsors {
        grid-area: sponsors;
        overflow-x: scroll;
        padding: 0;
        margin-bottom: var(--gap);

        @include mq(xs) {
            justify-content: space-between;
            flex-wrap: wrap;
            overflow-x: hidden;
        }

        @include mq(nb) {
            margin-bottom: 0;
        }

        a {
            display: flex;
            margin: var(--gap-half);
            height: 50px;

            &:hover {
                --link-color-a: white;
            }

            @include mq(xs) {
                margin: 0;
            }
        }
    }

    &__legal-links {
        grid-area: links;
        flex-direction: column;

        @include mq($until: nb) {
            margin-bottom: $gap-largest;
        }

        li {
            text-align: left;
            margin-bottom: $gap-smallest;

            @include mq(xs) {
                text-align: center;
                margin-bottom: 0;
                margin-right: $gap-element;
            }

            @include mq(nb) {
                text-align: left;
            }
        }

        ul {
            padding: 0;
            margin: 0;
            columns: 2;

            @include mq(xs) {
                columns: 5;
                display: flex;
                justify-content: center;
            }

            @include mq(nb) {
                justify-content: flex-start;
            }
        }
    }

    &__codes {
        display: flex;
        flex-direction: column;
        margin-top: $gap-large;

        @include mq(xs) {
            flex-direction: row;
            margin-top: $gap-large;
        }

        p {
            margin-right: $gap;
            margin-bottom: $gap-smallest;
            font-size: $font-size-smallest;
            line-height: $line-height;

            @include mq(xs) {
                margin-bottom: $gap;
                font-size: $font-size-small;
            }
        }

    }

    .h-underline {
        --u-gap: 1rem;

        @include mq(nb) {
            --u-gap: 2.5rem;
        }
    }

}

#socials-wechatqr {
    display: none;
    padding: $gap-element;

    img {
        margin-left: auto;
        margin-right: auto;
    }
}
